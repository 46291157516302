import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
import { initGA, trackPageView } from "./services/analytics";
import ProtectedRoute from "./components/ProtectedRoute";
import HomePage from "./components/HomePage";
import Features from "./components/Features";
import About from "./components/About";
import Contact from "./components/Contact";
import Terms from "./components/Terms";
import Privacy from "./components/Privacy";
import Pricing from "./components/Pricing";
import Providers from "./components/Providers";
import Developers from "./components/Developers";
import Download from "./components/Download";
import SignUp from "./components/SignUp";
import WaitingList from "./components/WaitingList";
import DataRoom from "./components/DataRoom";
import Login from "./components/Login";
import TeamMember from "./components/TeamMember";
import BusinessCard from "./components/BusinessCard";
import { AuthProvider } from "./context/AuthContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
// Initialize Google Analytics
initGA();

// Analytics wrapper component
const AnalyticsWrapper = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    trackPageView(location.pathname);
  }, [location]);

  return children;
};

root.render(
  <React.StrictMode>
    <Router>
      <AnalyticsWrapper>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/features" element={<Features />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/providers" element={<Providers />} />
          <Route path="/developers" element={<Developers />} />
          <Route path="/download" element={<Download />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/waitlist" element={<WaitingList />} />
          <Route path="/team/:id" element={<TeamMember />} />
          <Route path="/code/:id" element={<BusinessCard />} />
          {/* edirect /chase to /team/chase */}
          <Route
            path="/chase"
            element={<Navigate to="/team/chase" replace />}
          />
          <Route path="/jon" element={<Navigate to="/team/jon" replace />} />
          <Route path="/jeff" element={<Navigate to="/team/jeff" replace />} />

          <Route
            path="/login"
            element={
              <AuthProvider>
                <Login />
              </AuthProvider>
            }
          />
          <Route
            path="/dataroom"
            element={
              <AuthProvider>
                <ProtectedRoute>
                  <DataRoom />
                </ProtectedRoute>
              </AuthProvider>
            }
          />
          <Route path="*" element={<HomePage />} />
        </Routes>
      </AnalyticsWrapper>
    </Router>
  </React.StrictMode>
);
